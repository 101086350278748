import { getGlobalData, handleHomePage } from "@api/handlers";
import { layoutAdapter } from "@layout/Layout.adapter";
import { homePageAdapter } from "@page-components/home/Home.adapter";
import { isPreview } from "@utils/is-preview/isPreview";
import { GetStaticProps } from "next";
import { Pages } from "../components/pages";

export const getStaticProps: GetStaticProps = async (context) => {
  const previewMode = isPreview(context);
  if (!context.locale) throw new Error("Locale is not defined");

  const response = await handleHomePage({
    preview: previewMode,
    locale: context.locale,
  });

  const globalData = await getGlobalData({ locale: context.locale });
  const globalProps = layoutAdapter(globalData);

  const data = homePageAdapter({
    ...response,
  });

  return { props: { ...data, ...globalProps }, revalidate: false };
};
export default Pages.Home;
